import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import apiClient from "./data/apiClient";
import { Layout } from "./Layout/Layout";
import { LoginPage } from "./pages/auth/LoginPage";
import { SignUpPage } from "./pages/auth/SignUpPage";
import { GenerateLessonPage } from "./pages/GenerateLessonPage";
import { JourneyPage } from "./pages/JourneyPage";
import { LessonPage } from "./pages/LessonPage";
import { PracticeFinishedPage } from "./pages/PracticeFinishedPage";
import { PracticePage } from "./pages/PracticePage/PracticePage";
import { VocabPage } from "./pages/VocabPage/VocabPage";
import { useProvideAuth } from "./utils/useProvideAuth";

const GlobalStyleComponent = createGlobalStyle`
  body {
    font-family: "Roboto", sans-serif;
    margin: 0;
    color: hsl(214, 20%, 30%);
  }

  * {
    box-sizing: border-box;
  }
`;

function App() {
  const [currentCourseId, setCurrentCourseId] = useState(() => {
    const localStorageValue = localStorage.getItem("currentCourseId");
    return localStorageValue ? parseInt(localStorageValue) : null;
  });

  const [courses, setCourses] = useState([]);
  const [hasLoadedCourses, setHasLoadedCourses] = useState(false);

  const { user, hasLoadedUser } = useProvideAuth({
    onLogout: () => {
      setCurrentCourseId(null);
      localStorage.setItem("currentCourseId", null);
      setCourses([]);
      setHasLoadedCourses(false);
      setIsNewLanguageDialogueOpen(false);
    },
  });

  const [isNewLanguageDialogueOpen, setIsNewLanguageDialogueOpen] =
    useState(false);

  useEffect(() => {
    if (user) {
      apiClient.getCourses(user.uid).then((courses) => {
        setHasLoadedCourses(true);
        if (!currentCourseId && courses.length > 0) {
          setCurrentCourseId(courses[0].id);
          localStorage.setItem("currentCourseId", courses[0].id);
        }
        setCourses(courses);
      });
    } else {
      setCourses([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const currentCourse = courses.find((course) => course.id === currentCourseId);
  const currentCourseLessons = currentCourse?.lessons || [];

  const layoutProps = {
    user,
    hasLoadedUser,
    hasLoadedCourses,
    isNewLanguageDialogueOpen,
    setIsNewLanguageDialogueOpen,
    currentCourseId,
    setCurrentCourseId: (newValue) => {
      setCurrentCourseId(newValue);
      localStorage.setItem("currentCourseId", newValue);
    },
    courses,
    setCourses,
  };

  const setLessonOnCourses = (newLesson) => {
    setCourses((currentCourses) =>
      currentCourses.map((course) => {
        if (course.id === newLesson.courseId) {
          return {
            ...course,
            lessons: course.lessons.map((lesson) =>
              lesson.id === newLesson.id ? newLesson : lesson
            ),
          };
        }
        return course;
      })
    );
  };

  const hydrateLesson = async (lessonId) => {
    const hydratedLesson = await apiClient.hydrateLesson({ lessonId });
    setLessonOnCourses(hydratedLesson);
  };

  const updateLesson = async (updatedLesson) => {
    setLessonOnCourses(updatedLesson);
    hydrateLesson(updatedLesson.id);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout {...layoutProps}>
              <JourneyPage currentCourse={currentCourse} />
            </Layout>
          }
        />
        <Route
          path="/sign-up"
          element={
            <Layout {...layoutProps} userRequirement="forbidden" hideSignUp>
              <SignUpPage />
            </Layout>
          }
        />
        <Route
          path="/login"
          element={
            <Layout {...layoutProps} userRequirement="forbidden" hideLogin>
              <LoginPage />
            </Layout>
          }
        />
        <Route
          path="/lessons/generate"
          element={
            <Layout {...layoutProps} backTo="/" hideActionsSection>
              <GenerateLessonPage
                user={user}
                addLesson={(newLesson) => {
                  setCourses(
                    courses.map((course) => {
                      if (course.id === newLesson.courseId) {
                        return {
                          ...course,
                          lessons: [...course.lessons, newLesson],
                        };
                      }
                      return course;
                    })
                  );
                  hydrateLesson(newLesson.id);
                }}
                currentCourseId={currentCourseId}
              />
            </Layout>
          }
        />
        <Route
          path="/lessons/:lessonId"
          element={
            <Layout {...layoutProps} backTo="/" hideActionsSection={true}>
              <LessonPage
                lessons={currentCourseLessons}
                course={currentCourse}
                updateLesson={updateLesson}
              />
            </Layout>
          }
        />
        <Route
          path="/lessons/:lessonId/practice"
          element={
            <PracticePage
              lessons={currentCourseLessons}
              {...{ layoutProps }}
              updateLesson={setLessonOnCourses}
            />
          }
        />
        <Route
          path="/lessons/:lessonId/finished"
          element={
            <PracticeFinishedPage
              lessons={currentCourseLessons}
              {...{ layoutProps }}
            />
          }
        />

        <Route
          path="/vocab"
          element={
            <Layout
              {...layoutProps}
              title="Vocab"
              backTo="/"
              hideActionsSection={true}
            >
              <VocabPage {...{ user, currentCourse }} />
            </Layout>
          }
        />
      </Routes>
      <CssBaseline />
      <GlobalStyleComponent />
    </>
  );
}

const AppWithRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppWithRouter;
