import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import apiClient from "../../data/apiClient";

const Root = styled.div`
  margin-top: 32px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

const SomethingWrongLinkButton = styled.button`
  border: none;
  background-color: transparent;
  color: hsl(214, 100%, 30%);
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: hsl(214, 100%, 40%);
  }
`;

const TextInput = styled.input`
  padding: 10px;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  border: solid 1px hsl(214, 100%, 80%);
  font-size: 16px;
  color: hsl(214, 50%, 30%);
`;

const PrimaryButton = styled.button`
  height: 40.5px;
  padding: 12px 18px;
  border-radius: 4px;
  border: none;
  background-color: hsl(214, 100%, 50%);
  color: hsl(214, 100%, 100%);
  font-weight: bold;
  cursor: pointer;

  padding-top: 8px;
  overflow: hidden;
  line-height: 2;

  &:hover {
    background-color: hsl(214, 100%, 60%);
  }

  &:disabled {
    background-color: hsl(214, 100%, 30%);
    cursor: not-allowed;
  }
`;

export const LessonRefinement = ({ lesson, updateLesson }) => {
  const navigate = useNavigate();
  const [expandedReason, setExpandedReason] = useState(null);
  const [problemDescription, setProblemDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Root>
      {!expandedReason && (
        <>
          <SomethingWrongLinkButton
            onClick={() => setExpandedReason("suggestion")}
          >
            Want to change something?
          </SomethingWrongLinkButton>{" "}
          /{" "}
          <SomethingWrongLinkButton
            onClick={() => setExpandedReason("problem")}
          >
            Something wrong?
          </SomethingWrongLinkButton>
        </>
      )}
      {expandedReason && (
        <>
          <TextInput
            placeholder={
              expandedReason === "problem"
                ? "What's the problem? (optional)"
                : "Describe the change you'd like"
            }
            onChange={(e) => {
              setProblemDescription(e.target.value);
            }}
          />
          <PrimaryButton
            onClick={async () => {
              setIsSubmitting(true);
              const newVersion = await apiClient.regenerateLesson({
                lessonId: lesson.id,
                problem: problemDescription,
              });
              updateLesson(newVersion);
              navigate(`/lessons/${newVersion.slug}?new=true`, {
                replace: true,
              });
              setExpandedReason(false);
              setIsSubmitting(false);
            }}
            disabled={isSubmitting}
          >
            Regenerate lesson
          </PrimaryButton>
        </>
      )}
    </Root>
  );
};
