import { useMatch } from "react-router-dom";
import { Layout } from "../../Layout/Layout";
import { PracticePageContent } from "./PracticePageContent";

export const PracticePage = ({ lessons, layoutProps, updateLesson }) => {
  const {
    params: { lessonSlug },
  } = useMatch("/lessons/:lessonSlug/practice");
  const lesson = lessons.find((lesson) => lesson.slug === lessonSlug);
  return (
    <Layout
      {...layoutProps}
      exitTo={`/lessons/${lessonSlug}`}
      title={lesson?.title}
      hideActionsSection={true}
    >
      <PracticePageContent
        {...{
          lessons,
          updateLesson,
        }}
      />
    </Layout>
  );
};
