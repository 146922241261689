export const topicSuggestions = [
  "Cutlery",
  "Wakeboarding",
  "Private Equity",
  "Elevators",
  "Fossils",
  "Vintage Cameras",
  "Cacti",
  "Solar Panels",
  "Cricket",
  "Fencing",
  "Drones",
  "Astrology",
  "Lighthouses",
  "Origami",
  "Stamps",
  "Typewriters",
  "Whale Watching",
  "Fortune Telling",
  "Submarines",
  "Vinyl Floors",
  "Beekeeping",
  "Laser Tag",
  "Fireworks",
  "Motorcycles",
  "Chess",
  "Cryptocurrency",
  "Opera",
  "Sharks",
  "Trains",
  "Sculpture",
  "Helicopters",
  "Violin",
  "Skyscrapers",
  "Butterflies",
  "Comic Strips",
  "Lawnmowers",
  "Astronomy",
  "Taxidermy",
  "Graffiti",
  "Reptiles",
  "Cheese Making",
  "Dictionaries",
  "Barbecues",
  "Ping Pong",
  "Elections",
  "Quilting",
  "Paper Airplanes",
  "Harps",
  "Kites",
  "Favorite Snacks",
  "Camping Spots",
  "Craft Beers",
  "Roadside Diners",
  "Retro Games",
  "Thrift Finds",
  "Board Games",
  "Local Legends",
  "Dog Parks",
  "Coffee Shops",
  "Hidden Beaches",
  "Street Art",
  "Vinyl Records",
  "Vintage Cars",
  "Farmers Markets",
  "Urban Myths",
  "Pop-up Shops",
  "Pizza Toppings",
  "Secret Recipes",
  "Book Clubs",
  "Comic Books",
  "Garden Gnomes",
  "Haunted Houses",
  "Night Markets",
  "Live Music",
  "Classic Movies",
  "Urban Gardens",
  "Road Trips",
  "Street Food",
  "Photo Walks",
  "Tiny Homes",
  "Jazz Cafes",
  "Skate Parks",
  "Mystery Novels",
  "Film Festivals",
  "Aquariums",
  "Midnight Snacks",
  "Puzzle Rooms",
  "Mountain Trails",
  "Karaoke Bars",
  "Brewery Tours",
  "Street Performers",
  "Local Artists",
  "Farm Stays",
  "Pottery Classes",
  "Escape Rooms",
  "Food Trucks",
  "Scary Stories",
  "Bike Rides",
];
