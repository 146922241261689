import { useEffect, useState } from "react";
import { Link, useMatch, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import apiClient from "../../data/apiClient";
import { useWindowWidth } from "../../utils/useWindowWidth";
import { LessonRefinement } from "./LessonRefinement";

const Root = styled.div`
  background-color: hsl(214, 100%, 98%);
  min-height: 100vh;
`;
const Title = styled.h1`
  text-align: center;
`;

const PracticeButton = styled(Link)`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px hsl(214, 100%, 80%);
  background-color: hsl(214, 100%, 98%);
  color: hsl(214, 50%, 30%);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 20px;

  &:hover {
    background-color: hsl(214, 100%, 90%);
  }
  &:active {
    background-color: hsl(214, 100%, 80%);
  }
  &:visited {
    color: hsl(214, 50%, 30%);
  }
`;

const PracticeButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PageCardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PageCard = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: hsl(214, 100%, 100%);
  margin-top: 16px;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 4px 8px hsla(214, 50%, 30%, 0.1),
    0 8px 16px hsla(214, 50%, 30%, 0.1);

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InLessonMessage = styled.div``;

const HighlightBox = styled.div`
  border: 1px solid hsl(214, 100%, 85%);
  background-color: hsl(214, 100%, 98%);
  color: hsl(214, 50%, 40%);
  padding: 8px;
  border-radius: 8px;
`;

export const LessonPage = ({ lessons, course, updateLesson }) => {
  const windowWidth = useWindowWidth();
  const {
    params: { lessonSlug },
  } = useMatch("/lessons/:lessonSlug");
  const [searchParams] = useSearchParams();
  const isNew = searchParams.get("new") === "true";
  const lesson = lessons.find((lesson) => lesson.slug === lessonSlug);

  const [vocabData, setVocabData] = useState(null);

  useEffect(() => {
    if (lesson) {
      const fetchVocabData = async () => {
        const newVocabData = await apiClient.getVocabForLesson({
          lessonId: lesson.id,
        });
        setVocabData(newVocabData);
      };
      fetchVocabData();
    }
  }, [lessonSlug, lesson]);

  if (!lesson) {
    return "Lesson not found";
  }
  return (
    <Root>
      <PageCardContainer>
        <PageCard>
          <Title>{lesson.title}</Title>
          <PracticeButtonWrapper>
            <PracticeButton to={`/lessons/${lessonSlug}/practice`}>
              Practice
            </PracticeButton>
          </PracticeButtonWrapper>
          {windowWidth >= 450 && (
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>English</th>
                  <th style={{ textAlign: "left" }}>{course.language}</th>
                </tr>
              </thead>
              <tbody>
                {lesson.challenges.map((challenge, index) => (
                  <tr key={index}>
                    <td>{challenge.sourcePhrase}</td>
                    <td>{challenge.targetPhrase}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {windowWidth < 450 && (
            <>
              <InLessonMessage>In this lesson:</InLessonMessage>
              <ul>
                {lesson.challenges.map((challenge, index) => (
                  <li key={index} style={{ marginTop: 8 }}>
                    {challenge.sourcePhrase}
                    <ul>
                      <li style={{ marginTop: 4 }}>{challenge.targetPhrase}</li>
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          )}

          {vocabData?.newFrom100?.length > 0 && (
            <HighlightBox>
              <strong>{vocabData.newFrom100?.length}</strong> new words from the
              100 most common words
            </HighlightBox>
          )}

          {isNew && (
            <LessonRefinement
              {...{
                lesson,
                updateLesson,
              }}
            />
          )}
        </PageCard>
      </PageCardContainer>
    </Root>
  );
};
