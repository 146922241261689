import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../data/auth";
import { AuthFormPageContent } from "./AuthFormPageContent";

export const SignUpPage = () => {
  const navigate = useNavigate();
  return (
    <AuthFormPageContent
      title="Sign Up"
      onSubmit={async ({ email, password }) => {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate("/");
      }}
      submitText="Sign up"
    />
  );
};
