import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { NewLanguageDialogue } from "../components/NewLanguageDialogue";
import { AppBar } from "./AppBar/AppBar";
import { ScrollToTop } from "./ScrollToTop";

const Root = styled.div`
  background-color: hsl(214, 100%, 98%);
  min-height: 100vh;
`;

const Content = styled.div``;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderCircleWrapper = styled.div`
  animation: ${spin} 2s linear infinite;

  margin-top: 30vh;

  height: 50px;
`;

const LoaderCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid hsl(214, 100%, 80%);
  margin-left: 40px;
`;

export const Layout = ({
  user,
  hasLoadedUser,
  hasLoadedCourses,
  isNewLanguageDialogueOpen,
  setIsNewLanguageDialogueOpen,
  currentCourseId,
  setCurrentCourseId,
  courses,
  setCourses,
  children,
  userRequirement = "required",
  hideSignUp = false,
  hideLogin = false,
  hideActionsSection = false,
  backTo,
  exitTo,
  title,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasLoadedUser) {
      return;
    }
    if (userRequirement === "required" && !user) {
      navigate("/login", { replace: true });
    } else if (userRequirement === "forbidden" && user) {
      navigate("/", { replace: true });
    }
  }, [user, hasLoadedUser, userRequirement, navigate]);

  useEffect(() => {
    if (user && hasLoadedCourses && courses.length === 0) {
      setIsNewLanguageDialogueOpen(true);
    }
  }, [hasLoadedCourses, courses, setIsNewLanguageDialogueOpen, user]);

  if (
    !hasLoadedUser ||
    (user && !hasLoadedCourses) ||
    (userRequirement === "required" && !user) ||
    (userRequirement === "forbidden" && user)
  ) {
    return (
      <Root>
        <LoaderContainer>
          <LoaderCircleWrapper>
            <LoaderCircle />
          </LoaderCircleWrapper>
        </LoaderContainer>
      </Root>
    );
  }

  return (
    <>
      {(courses.length > 0 || userRequirement !== "required") && (
        <Root>
          <AppBar
            {...{
              user,
              setIsNewLanguageDialogueOpen,
              currentCourseId,
              setCurrentCourseId,
              courses,
              hideSignUp,
              hideLogin,
              hideActionsSection,
              backTo,
              exitTo,
              title,
            }}
          />
          {((userRequirement === "required" && user) ||
            (userRequirement === "forbidden" && !user)) && (
            <Content>{children}</Content>
          )}
        </Root>
      )}

      {(isNewLanguageDialogueOpen || (user && courses.length === 0)) && (
        <NewLanguageDialogue
          addNewCourse={(newCourse) => {
            setCourses([...courses, newCourse]);
          }}
          userId={user?.uid}
          close={() => {
            setIsNewLanguageDialogueOpen(false);
          }}
          setCurrentCourseId={setCurrentCourseId}
          courses={courses}
          user={user}
        />
      )}
      <ScrollToTop />
    </>
  );
};
