import styled from "styled-components";

const Root = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: hsl(214, 100%, 98%);
  color: hsl(214, 70%, 40%);
  cursor: pointer;

  min-width: 75px;
`;

const Option = styled.option``;

export const LanguageSelector = ({
  setIsNewLanguageDialogueOpen,
  currentCourseId,
  setCurrentCourseId,
  courses,
}) => {
  return (
    <Root
      onChange={(event) => {
        if (event.target.value === "_new course") {
          setIsNewLanguageDialogueOpen(true);
        } else {
          setCurrentCourseId(parseInt(event.target.value));
        }
      }}
      value={currentCourseId}
    >
      {courses.map((course) => (
        <Option key={course.id} value={course.id}>
          {course.language}
        </Option>
      ))}
      <Option value="_new course">+ New language</Option>
    </Root>
  );
};
