import { useState } from "react";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

const Card = styled.div`
  background-color: hsl(214, 100%, 100%);
  border-radius: 8px;
  box-shadow: 0 2px 4px hsla(214, 20%, 30%, 0.1),
    0 8px 16px hsla(214, 20%, 30%, 0.1);
  padding: 16px;
  margin: 16px;
  max-width: 400px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextField = styled.input`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid hsl(214, 20%, 80%);
`;

const Button = styled.button`
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: hsl(214, 100%, 50%);
  color: hsl(214, 100%, 100%);
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: hsl(214, 100%, 60%);
  }

  &:disabled {
    background-color: hsl(214, 100%, 30%);
    cursor: not-allowed;
  }
`;

const customErrorMessages = {
  "auth/user-not-found": "User not found",
  "auth/wrong-password": "Wrong password",
  "auth/email-already-in-use": "Email already in use",
  "auth/invalid-email": "Invalid email",
  "auth/invalid-password": "Incorrect password",
  "auth/invalid-credential": "Something doesn't look right",
};

const getErrorMessage = (error) => {
  return customErrorMessages[error.code] || error.message;
};

export const AuthFormPageContent = ({ title, onSubmit, submitText }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  return (
    <Root>
      <Card>
        <h1>{title}</h1>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            setIsSubmitting(true);
            try {
              await onSubmit({ email, password });
            } catch (error) {
              console.log("Error", JSON.stringify(error, null, 2));
              setError(error);
              setIsSubmitting(false);
            }
          }}
        >
          <InputWrapper>
            <label htmlFor="email">Email</label>
            <TextField
              type="email"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              disabled={isSubmitting}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="password">Password</label>
            <TextField
              type="password"
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              disabled={isSubmitting}
            />
          </InputWrapper>
          {error && <p style={{ color: "red" }}>{getErrorMessage(error)}</p>}
          <Button
            style={{ marginTop: 16 }}
            type="submit"
            disabled={isSubmitting}
          >
            {submitText}
          </Button>
        </Form>
      </Card>
    </Root>
  );
};
