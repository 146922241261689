import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../data/auth";

export function useProvideAuth({ onLogout }) {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [hasLoadedUser, setHasLoadedUser] = useState(false);
  const prevUser = user;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      setHasLoadedUser(true);
      if (!user) {
        onLogout();
        if (prevUser) {
          navigate("/login");
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return {
    user,
    hasLoadedUser,
  };
}
