import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import apiClient from "../../data/apiClient";

const Root = styled.div`
  background-color: hsl(214, 100%, 98%);
  min-height: 100vh;
`;

const ChallengeCardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ChallengeCard = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: hsl(214, 100%, 100%);
  margin-top: 16px;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 4px 8px hsla(214, 50%, 30%, 0.1),
    0 8px 16px hsla(214, 50%, 30%, 0.1);

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const QuestionText = styled.div``;

const AnswerDisplay = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 0;
  min-height: 50px;
  flex-wrap: wrap;

  border-bottom: solid 1px hsl(214, 20%, 90%);
`;

const AnswerButtonList = styled.div`
  display: flex;
  gap: 8px;
  min-height: 34px;
  flex-wrap: wrap;

  margin-top: 32px;
`;

const AnswerButton = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  border: solid 1px hsl(214, 20%, 90%);
  background-color: hsl(214, 100%, 98%);
  cursor: pointer;

  &:hover {
    border-color: hsl(214, 40%, 90%);
    background-color: hsl(214, 100%, 96%);
    color: hsl(214, 50%, 25%);
  }

  ${(props) =>
    props.submissionState === "correct" &&
    `
    &:disabled {
      background-color: hsl(160, 100%, 90%);
      border-color: hsl(160, 100%, 80%);
      color: hsl(160, 70%, 25%);
      cursor: default;
    }
  `}
`;

const SubmitButton = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  border: solid 1px hsl(214, 100%, 80%);
  background-color: hsl(214, 100%, 96%);
  color: hsl(214, 50%, 30%);
  cursor: pointer;

  align-self: flex-end;

  &:hover {
    background-color: hsl(214, 100%, 90%);
  }
  &:active {
    background-color: hsl(214, 100%, 80%);
  }
`;

const SubmitButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const ResultText = styled.div`
  font-style: italic;
`;

export const PracticePageContent = ({ lessons, updateLesson }) => {
  const {
    params: { lessonSlug },
  } = useMatch("/lessons/:lessonSlug/practice");
  const navigate = useNavigate();
  const lesson = lessons.find((lesson) => lesson.slug === lessonSlug);
  const [currentChallengeIndex, setCurrentChallengeIndex] = useState(0);
  const [answerSelection, setAnswerSelection] = useState([]);
  const [submissionState, setSubmissionState] = useState("unsubmitted");

  const currentChallenge = lesson?.challenges[currentChallengeIndex];

  const [answerOptionWords, setAnswerOptionWords] = useState(() => {
    const words = currentChallenge?.targetPhrase
      .split(" ")
      .map((word) => word.toLowerCase());
    return words.sort(() => Math.random() - 0.5);
  });

  useEffect(() => {
    const words = currentChallenge?.targetPhrase
      .split(" ")
      .map((word) => word.toLowerCase());
    setAnswerOptionWords(words.sort(() => Math.random() - 0.5));
  }, [currentChallenge?.targetPhrase]);

  return (
    <Root>
      <ChallengeCardContainer>
        <ChallengeCard>
          <QuestionText>
            {currentChallenge?.sourcePhrase.split(" ").map((word, index) => (
              <span key={`${word}-${index}`}>{word} </span>
            ))}
          </QuestionText>

          <AnswerDisplay>
            {answerSelection.map((answer, index) => (
              <AnswerButton
                key={`${answer}-${index}`}
                onClick={() => {
                  setAnswerSelection(
                    answerSelection.filter(
                      (selectedAnswer) => selectedAnswer !== answer
                    )
                  );
                  setSubmissionState("unsubmitted");
                }}
                submissionState={submissionState}
                disabled={submissionState === "correct"}
              >
                {answer}{" "}
              </AnswerButton>
            ))}
          </AnswerDisplay>

          <AnswerButtonList>
            {answerOptionWords.map((word, index) => (
              <AnswerButton
                key={index}
                onClick={() => {
                  setAnswerSelection([...answerSelection, word]);
                  setSubmissionState("unsubmitted");
                }}
                style={{
                  visibility: answerSelection.includes(word) && "hidden",
                }}
                disabled={submissionState === "correct"}
                data-cy="answer-option-word"
              >
                {word}
              </AnswerButton>
            ))}
          </AnswerButtonList>

          <SubmitButtonSection>
            {submissionState === "incorrect" && (
              <ResultText>That's not quite right. Try again!</ResultText>
            )}
            {(submissionState === "unsubmitted" ||
              submissionState === "incorrect") && (
              <SubmitButton
                onClick={() => {
                  const isCorrect =
                    answerSelection.join(" ") ===
                    currentChallenge?.targetPhrase.toLowerCase();
                  if (isCorrect) {
                    setSubmissionState("correct");
                  } else {
                    setSubmissionState("incorrect");
                  }
                  apiClient.challengeSubmitted({
                    challengeId: currentChallenge.id,
                    isCorrect,
                  });
                }}
              >
                Check
              </SubmitButton>
            )}
            {submissionState === "correct" && (
              <>
                <ResultText>That's right!</ResultText>
                <SubmitButton
                  onClick={async () => {
                    if (
                      currentChallengeIndex ===
                      lesson?.challenges.length - 1
                    ) {
                      const newProgress = await apiClient.lessonFinished({
                        lessonId: lesson.id,
                      });
                      updateLesson({
                        ...lesson,
                        lessonProgresses: [
                          ...lesson.lessonProgresses,
                          newProgress,
                        ],
                      });
                      navigate(`/lessons/${lessonSlug}/finished`);
                    } else {
                      setCurrentChallengeIndex(currentChallengeIndex + 1);
                      setAnswerSelection([]);
                      setSubmissionState("unsubmitted");
                    }
                  }}
                >
                  {currentChallengeIndex === lesson?.challenges.length - 1 &&
                    "Continue"}
                  {currentChallengeIndex !== lesson?.challenges.length - 1 &&
                    "Next"}
                </SubmitButton>
              </>
            )}
          </SubmitButtonSection>
        </ChallengeCard>
      </ChallengeCardContainer>
    </Root>
  );
};
