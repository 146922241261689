import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../data/auth";
import { AuthFormPageContent } from "./AuthFormPageContent";

export const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <AuthFormPageContent
      title="Log in"
      onSubmit={async ({ email, password }) => {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/");
      }}
      submitText="Log in"
    />
  );
};
