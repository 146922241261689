import { Link } from "react-router-dom";
import styled from "styled-components";
import { ProgressCard } from "./progress/ProgressCard";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VocabLinkSection = styled.div`
  width: 100%;
  text-align: right;
  padding: 10px;
  box-sizing: border-box;

  margin-bottom: 16px;
`;

const VocabLink = styled(Link)`
  color: hsl(214, 30%, 40%);
  background-color: hsl(214, 100%, 92%);
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: hsl(214, 100%, 89%);
  }
  &:active {
    background-color: hsl(214, 100%, 70%);
  }
  &:visited {
    color: hsl(214, 30%, 40%);
  }
`;

const LessonsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 48px 10px 50px 10px;
`;

const LessonButton = styled(Link)`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px hsl(214, 100%, 80%);
  background-color: hsl(214, 100%, 98%);
  color: hsl(214, 50%, 30%);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${(props) =>
    props.isCompleted &&
    `
    background-color: hsl(160, 100%, 97%);
    color: hsl(160, 50%, 30%);
    border-color: hsl(160, 50%, 80%);


  &:hover&:hover {
    background-color: hsl(160, 100%, 90%);
  }
  &:active&:active {
    background-color: hsl(160, 100%, 80%);
  }

    &:visited&:visited {
      color: hsl(160, 50%, 30%);
    }
  `}

  &:hover {
    background-color: hsl(214, 100%, 90%);
  }
  &:active {
    background-color: hsl(214, 100%, 80%);
  }
  &:visited {
    color: hsl(214, 50%, 30%);
  }
`;

export const JourneyPage = ({ currentCourse }) => {
  return (
    <Root>
      {currentCourse.lessons[0] && (
        <VocabLinkSection>
          <VocabLink to="/vocab">Vocab</VocabLink>
        </VocabLinkSection>
      )}
      <ProgressCard progress={currentCourse.progress} />
      <LessonsList>
        {currentCourse.lessons.map((lesson) => (
          <LessonButton
            key={lesson.slug}
            to={`/lessons/${lesson.slug}`}
            isCompleted={lesson.lessonProgresses?.length > 0}
            data-cy="lesson-button"
          >
            {lesson.title}
          </LessonButton>
        ))}
        <LessonButton to={`/lessons/generate`}>
          {currentCourse.lessons[0] ? "Next lesson" : "Create first lesson"}
        </LessonButton>
      </LessonsList>
    </Root>
  );
};
