import { Link, useMatch } from "react-router-dom";
import styled from "styled-components";
import { Layout } from "../../Layout/Layout";

const Root = styled.div`
  background-color: hsl(214, 100%, 98%);
  min-height: 100vh;
`;

const Message = styled.p`
  text-align: center;
`;

const PracticeButton = styled(Link)`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px hsl(214, 100%, 80%);
  background-color: hsl(214, 100%, 98%);
  color: hsl(214, 50%, 30%);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 20px;

  &:hover {
    background-color: hsl(214, 100%, 90%);
  }
  &:active {
    background-color: hsl(214, 100%, 80%);
  }
  &:visited {
    color: hsl(214, 50%, 30%);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: hsl(214, 10%, 80%);
    border-color: hsl(214, 10%, 80%);
    color: hsl(214, 10%, 30%);
  }
`;

const PracticeButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PracticeFinishedPage = ({ lessons, layoutProps }) => {
  const {
    params: { lessonSlug },
  } = useMatch("/lessons/:lessonSlug/finished");
  const lesson = lessons.find((lesson) => lesson.slug === lessonSlug);
  const currentLessonIndex = lessons.indexOf(lesson);
  const nextLesson = lessons[currentLessonIndex + 1];

  return (
    <Layout
      {...layoutProps}
      backTo={`/lessons/${lessonSlug}`}
      title={lesson.title}
      hideActionsSection
    >
      <Root>
        <br />
        <Message>Great work!</Message>
        <br />
        {nextLesson && (
          <>
            <br />
            <Message>Next topic:</Message>
          </>
        )}
        <PracticeButtonWrapper>
          <PracticeButton
            to={
              nextLesson ? `/lessons/${nextLesson.slug}` : "/lessons/generate"
            }
          >
            {nextLesson?.title || "Next lesson"}
          </PracticeButton>
        </PracticeButtonWrapper>
      </Root>
    </Layout>
  );
};
