import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import apiClient from "../../data/apiClient";
import { topicSuggestions } from "./topicSuggestionss";

const Root = styled.div``;
const Title = styled.h1`
  text-align: center;
`;

const TextInput = styled.input`
  padding: 10px;
  margin-bottom: 4px;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  border: solid 1px hsl(214, 100%, 80%);
  font-size: 16px;
  color: hsl(214, 50%, 30%);
`;

const SuggestionsText = styled.div`
  font-size: 12px;
  color: hsl(214, 20%, 50%);

  padding: 3px 0;
`;

const Suggestions = styled.div`
  display: flex;

  padding: 0 4px;
`;

const SuggestionsList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SuggestionButton = styled.button`
  padding: 2px 6px;
  margin: 5px;
  border-radius: 4px;
  border: solid 1px hsl(214, 100%, 90%);
  background-color: hsl(214, 100%, 98%);
  color: hsl(214, 50%, 50%);
  cursor: pointer;
  font-size: 10px;

  &:hover {
    background-color: hsl(214, 100%, 94%);
  }
  &:active {
    background-color: hsl(214, 100%, 90%);
  }
`;

const GenerateButton = styled.button`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px hsl(214, 100%, 80%);
  background-color: hsl(214, 100%, 98%);
  color: hsl(214, 50%, 30%);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 20px;

  &:hover {
    background-color: hsl(214, 100%, 90%);
  }
  &:active {
    background-color: hsl(214, 100%, 80%);
  }
  &:visited {
    color: hsl(214, 50%, 30%);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: hsl(214, 10%, 80%);
    border-color: hsl(214, 10%, 80%);
    color: hsl(214, 10%, 30%);
  }
`;

const GenerateButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GenerateLessonPage = ({ user, addLesson, currentCourseId }) => {
  const navigate = useNavigate();

  const [nextLessonConfig, setNextLessonConfig] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [suggestedTopics, setSuggestedTopics] = useState([]);

  useEffect(() => {
    let threeRandomTopics = [];
    const remainingTopics = topicSuggestions.slice();
    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * remainingTopics.length);
      threeRandomTopics.push(remainingTopics.splice(randomIndex, 1)[0]);
    }
    setSuggestedTopics(threeRandomTopics);
  }, []);

  return (
    <Root>
      <Title>Next lesson</Title>
      <GenerateButtonWrapper>
        <TextInput
          placeholder="Topic (optional)"
          value={nextLessonConfig.topic || ""}
          onChange={(e) =>
            setNextLessonConfig({
              ...nextLessonConfig,
              topic: e.target.value,
            })
          }
        />
        <Suggestions>
          <SuggestionsText> try: </SuggestionsText>
          <SuggestionsList>
            {suggestedTopics.map((topic) => (
              <SuggestionButton
                key={topic}
                onClick={() =>
                  setNextLessonConfig({
                    ...nextLessonConfig,
                    topic,
                  })
                }
              >
                {topic}
              </SuggestionButton>
            ))}
          </SuggestionsList>
        </Suggestions>
        <GenerateButton
          disabled={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true);
            try {
              const newLesson = await apiClient.generateLesson({
                userInputs: nextLessonConfig,
                userId: user.uid,
                currentCourseId,
              });
              addLesson(newLesson);
              navigate(`/lessons/${newLesson.slug}?new=true`, {
                replace: true,
              });
            } catch (error) {
              setHasError(true);
              setIsSubmitting(false);
              console.error(error);
              Sentry.captureException(error);
            }
          }}
        >
          Generat{isSubmitting ? "ing..." : "e"}
        </GenerateButton>
        <br />
        <br />
      </GenerateButtonWrapper>
      {hasError && !isSubmitting && (
        <p style={{ textAlign: "center" }}>
          There was an error generating the lesson.
          <br />
          Please try again shortly.
        </p>
      )}
    </Root>
  );
};
