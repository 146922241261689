import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseProdConfig = {
  apiKey: "AIzaSyAlS6OwGx6dxvCd-va9n48IKMZghrsAFLY",
  authDomain: "ll-language.firebaseapp.com",
  projectId: "ll-language",
  storageBucket: "ll-language.appspot.com",
  messagingSenderId: "400637260582",
  appId: "1:400637260582:web:70dddccbebeb7434a247e7",
  measurementId: "G-S28C7N4C79",
};

const app = initializeApp(firebaseProdConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
