import styled from "styled-components";

const Root = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

const Card = styled.div`
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 12px rgba(0, 0, 0, 0.08);

  display: flex;
  align-items: center;
  gap: 16px;
`;

const Title = styled.h5`
  margin: 0;
  padding: 0;
  color: #666;

  white-space: nowrap;
`;

const ProgressBarRoot = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #f1f1f1;

  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ProgressBarLiquid = styled.div`
  height: 100%;
  background-image: linear-gradient(to right, #00c9ff, #92fe9d);
  width: ${(props) => props.progress}%;

  ${(props) => {
    if (props.progress > 4) {
      return `border-radius: 0 4px 4px 0;`;
    }
  }};

  transition: width 0.5s;
`;

const ProgressNumbers = styled.div`
  display: flex;
  font-size: 12px;
  color: #777;

  white-space: nowrap;
`;

export const ProgressCard = ({ progress }) => {
  const first100WordsProgress = progress.words.first100.filter(
    (word) => word.progress_count > 0
  ).length;
  if (first100WordsProgress === 0) {
    return null;
  }
  return (
    <Root>
      <Card>
        <Title>Common words</Title>
        <ProgressBarRoot>
          <ProgressBarLiquid progress={first100WordsProgress} />
        </ProgressBarRoot>
        <ProgressNumbers>{first100WordsProgress} / 100</ProgressNumbers>
      </Card>
    </Root>
  );
};
