import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { auth } from "../../data/auth";
import { LanguageSelector } from "./LanguageSelector";

const Root = styled.div`
  background-color: hsl(214, 100%, 100%);
  padding: 16px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  overflow: hidden;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  overflow: hidden;
`;

const BackLink = styled(Link)`
  color: hsl(214, 100%, 30%);
  text-decoration: none;

  &:visited {
    color: hsl(214, 100%, 30%);
  }

  &:hover {
    text-decoration: underline;
    color: hsl(214, 80%, 45%);
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: hsl(214, 20%, 50%);

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const UserEmail = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  max-width: 200px;
`;

const SignOutButton = styled.button`
  background-color: hsl(214, 100%, 100%);
  border: none;
  cursor: pointer;
  color: hsl(214, 100%, 30%);
  font-size: 16px;
  padding: 0;
  flex-shrink: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ActionsSection = styled.div`
  display: flex;
  gap: 24px;

  margin-left: auto;
  overflow: hidden;
  align-items: center;
`;

export const AppBar = ({
  user,
  setIsNewLanguageDialogueOpen,
  currentCourseId,
  setCurrentCourseId,
  courses,
  hideSignUp,
  hideLogin,
  hideActionsSection,
  backTo,
  exitTo,
  title,
}) => {
  return (
    <Root>
      {(backTo || exitTo || title) && (
        <TitleSection>
          {backTo && <BackLink to={backTo}>Back</BackLink>}
          {exitTo && <BackLink to={exitTo}>Exit</BackLink>}
          {title && <Title>{title}</Title>}
        </TitleSection>
      )}
      {user && !hideActionsSection && (
        <ActionsSection>
          <LanguageSelector
            {...{
              setIsNewLanguageDialogueOpen,
              currentCourseId,
              setCurrentCourseId,
              courses,
            }}
          />
          <SignOutButton
            onClick={async () => {
              await signOut(auth);
            }}
          >
            Sign out
          </SignOutButton>
          <UserEmail>{user.email}</UserEmail>
        </ActionsSection>
      )}
      {!user && (
        <ActionsSection>
          {!hideSignUp && <Link to="/sign-up">Sign up</Link>}
          {!hideLogin && <Link to="/login">Log in</Link>}
        </ActionsSection>
      )}
    </Root>
  );
};
