import { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styled from "styled-components";
import apiClient from "../data/apiClient";
import { useState } from "react";
import { languages } from "./languages";
import { useWindowWidth } from "../utils/useWindowWidth";

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  z-index: 1000;
  color: hsl(214, 20%, 30%);
  font-family: "Roboto", sans-serif;

  padding: 24px 8px;
`;

const DialogueRoot = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ isMobile }) => (isMobile ? "width: 100%;" : "")}
`;

const Title = styled.div`
  font-size: 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;

  margin-top: 60px;
`;

const PrimaryButton = styled.button`
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: hsl(214, 100%, 50%);
  color: hsl(214, 100%, 100%);
  font-weight: bold;
  cursor: pointer;

  width: 120px;

  &:hover {
    background-color: hsl(214, 100%, 60%);
  }

  &:disabled {
    background-color: hsl(214, 100%, 30%);
    cursor: not-allowed;
  }
`;

const SecondaryButton = styled.button`
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: hsl(214, 50%, 90%);
  color: hsl(214, 50%, 30%);
  font-weight: bold;
  cursor: pointer;

  width: 120px;

  &:hover {
    background-color: hsl(214, 50%, 80%);
  }

  &:disabled {
    background-color: hsl(214, 50%, 70%);
    cursor: not-allowed;
  }
`;

export const NewLanguageDialogue = ({
  addNewCourse,
  setCurrentCourseId,
  close,
  userId,
  courses,
  user,
}) => {
  const canClose = courses.length > 0;
  const [language, setLanguage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const windowWidth = useWindowWidth();
  const mouseDownElRef = useRef(null);
  const mouseUpElRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const setMouseDownEl = (event) => {
      mouseDownElRef.current = event.target;
    };
    const setMouseUpEl = (event) => {
      mouseUpElRef.current = event.target;
    };
    const closeOnEscape = (event) => {
      if (event.key === "Escape") {
        close();
      }
    };

    if (canClose) {
      document.addEventListener("mousedown", setMouseDownEl);
      document.addEventListener("mouseup", setMouseUpEl);
      document.addEventListener("keydown", closeOnEscape);
    }
    return () => {
      document.body.style.overflow = "auto";
      if (canClose) {
        document.removeEventListener("mousedown", setMouseDownEl);
        document.removeEventListener("mouseup", setMouseUpEl);
        document.removeEventListener("keydown", closeOnEscape);
      }
    };
  }, [canClose, close]);

  const submit = async () => {
    if (!language) {
      return;
    }
    setIsSubmitting(true);
    const newCourse = await apiClient.createCourse({
      language,
      userId,
      email: user.email,
    });
    addNewCourse(newCourse);
    setCurrentCourseId(newCourse.id);
    close();
  };

  return (
    <Root
      isMobile={windowWidth < 500}
      onClick={
        canClose
          ? (event) => {
              if (
                event.target === event.currentTarget &&
                mouseDownElRef.current === event.target &&
                mouseUpElRef.current === event.target
              ) {
                close();
              }
            }
          : undefined
      }
    >
      <DialogueRoot isMobile={windowWidth < 500}>
        <Title>Which language do you want to learn?</Title>

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={languages}
          sx={{ maxWidth: 300 }}
          renderInput={(params) => <TextField {...params} label="Language" />}
          onChange={(_event, value) => setLanguage(value?.label || "")}
          disabled={isSubmitting}
          size="small"
        />

        <ButtonGroup>
          {canClose && (
            <SecondaryButton onClick={close} disabled={isSubmitting}>
              Cancel
            </SecondaryButton>
          )}
          <PrimaryButton onClick={submit} disabled={isSubmitting}>
            Let's go!
          </PrimaryButton>
        </ButtonGroup>
      </DialogueRoot>
    </Root>
  );
};
