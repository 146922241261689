import { useEffect, useState } from "react";
import styled from "styled-components";
import apiClient from "../../data/apiClient";

const Root = styled.div`
  background-color: hsl(214, 100%, 98%);
  padding: 40px;
`;

const BaseWordSection = styled.details``;

const BaseWordSummary = styled.summary`
  cursor: pointer;
`;

const WordVariants = styled.div`
  margin-left: 20px;
`;

export const VocabPage = ({ user, currentCourse }) => {
  const [vocabByCourseId, setVocabByCourseId] = useState();

  useEffect(() => {
    const fetchVocab = async () => {
      const vocab = await apiClient.getVocab({ userId: user.uid });
      setVocabByCourseId(vocab.sort((a, b) => a.text.localeCompare(b.text)));
    };
    fetchVocab();
  }, [user.uid]);

  if (!vocabByCourseId) return null;

  return (
    <Root>
      {vocabByCourseId.map((baseWord) => (
        <BaseWordSection key={baseWord.id}>
          <BaseWordSummary>
            {baseWord.text} - {baseWord.translatedText}{" "}
            {baseWord.userExposureStats.total > 0
              ? `(${baseWord.userExposureStats.correct} / ${baseWord.userExposureStats.total})`
              : ""}
          </BaseWordSummary>
          <WordVariants>
            {baseWord.wordVariants.map((variant) => (
              <p key={variant.id}>
                {variant.text} - {variant.translatedText}
                {Object.entries(variant.version).length > 0 &&
                  ` (${Object.entries(variant.version)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(", ")})`}
              </p>
            ))}
          </WordVariants>
        </BaseWordSection>
      ))}
    </Root>
  );
};
