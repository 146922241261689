import { getAuth } from "firebase/auth";

const apiRootUrls = {
  development: "http://localhost:3216",
  production: "https://ll-bxp7.onrender.com",
};

const apiRootUrl = apiRootUrls[process.env.NODE_ENV];

const apiFetch = async (path, options = {}) => {
  const auth = getAuth();
  const idToken = await auth.currentUser.getIdToken();
  return fetch(apiRootUrl + path, {
    method: "GET",
    ...options,
    body: JSON.stringify(options.body),
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(
        `API request failed with status ${res.status} ${res.statusText}`
      );
    }
    return res;
  });
};

class ApiClient {
  getCourses = async () => {
    const response = await apiFetch("/courses", {
      method: "GET",
    });
    return response.json();
  };
  generateLesson = async (data) => {
    const response = await apiFetch("/lessons", {
      method: "POST",
      body: {
        lesson: {
          course_id: data.currentCourseId,
          user_inputs: data.userInputs,
        },
      },
    });
    return response.json();
  };
  regenerateLesson = async (data) => {
    const response = await apiFetch(`/lessons/${data.lessonId}`, {
      method: "PATCH",
      body: {
        problem: data.problem,
      },
    });
    return response.json();
  };
  hydrateLesson = async (data) => {
    const response = await apiFetch(`/lessons/${data.lessonId}/hydrate`, {
      method: "PATCH",
    });
    return response.json();
  };
  lessonFinished = async (data) => {
    const response = await apiFetch(
      `/lessons/${data.lessonId}/lesson_progresses`,
      {
        method: "POST",
      }
    );
    return response.json();
  };
  challengeSubmitted = async (data) => {
    const response = await apiFetch(`/challenge_progresses`, {
      method: "POST",
      body: {
        challenge_progress: {
          challenge_id: data.challengeId,
          is_correct: data.isCorrect,
          user_input_type: "word_bank",
        },
      },
    });
    return response.json();
  };
  createCourse = async ({ language }) => {
    const response = await apiFetch("/courses", {
      method: "POST",
      body: {
        course: {
          language,
        },
      },
    });
    return response.json();
  };
  getVocab = async ({ userId }) => {
    const response = await apiFetch(`/vocab?user_id=${userId}`, {
      method: "GET",
    });

    return response.json();
  };
  getVocabForLesson = async ({ lessonId }) => {
    const response = await apiFetch(`/vocab/lessons/${lessonId}`, {
      method: "GET",
    });

    return response.json();
  }
}

const apiClient = new ApiClient();

export default apiClient;
